import request from '@/utils/request';

export function createBulk(data) {
  return request({
    url: '/v1/bulk/create',
    method: 'post',
    data,
  });
}

export function fetchAll(params) {
  return request({
    url: '/v1/bulk/listAll',
    method: 'get',
    params,
  });
}

export function forceEnable(id) {
  return request({
    url: `v1/bulk/forceEnable/${id}`,
    method: 'post',
  });
}

export function terminate(id) {
  return request({
    url: `v1/bulk/terminate/${id}`,
    method: 'post',
  });
}
export function fetchOngoing() {
  return request({
    url: '/v1/bulk/listOngoing',
    method: 'get',
  });
}

export function fetchOngoingByProduct(id) {
  return request({
    url: `v1/bulk/ongoingBulksByProduct/${id}`,
    method: 'get',
  });
}

export function fetchOrders(id) {
  return request({
    url: `v1/bulk/listOrders/${id}`,
    method: 'get',
  });
}

export function updateBulk(data) {
  return request({
    url: 'v1/bulk/update',
    method: 'post',
    data,
  });
}

export function findOngoing(params) {
  return request({
    url: '/v1/bulk/findOngoing',
    method: 'get',
    params,
  });
}

import request from '@/utils/request';

export function fetchActive() {
  return request({
    url: 'v1/captain/listActive',
    method: 'get',
  });
}

export function reassignCaptain(data) {
  return request({
    url: 'v1/captain/reassign',
    method: 'post',
    data,
  });
}

export function updateCaptainCommunity(data) {
  return request({
    url: 'v1/captain/updateCommunity',
    method: 'post',
    data,
  });
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',{staticClass:"form-container",attrs:{"shadow":"never"}},[_c('el-form',{ref:"createBulkForm",staticStyle:{"width":"600px"},attrs:{"model":_vm.bulk,"label-width":"150px","size":"small"}},[_c('el-form-item',{attrs:{"label":"Product: ","prop":"productId","rules":[
        { required: true, message: 'Please select product', trigger: 'blur' } ]}},[_c('el-input',{attrs:{"disabled":true},model:{value:(_vm.productDisplay),callback:function ($$v) {_vm.productDisplay=$$v},expression:"productDisplay"}})],1),_c('el-form-item',{attrs:{"label":"Captain: ","prop":"captainIds","rules":[
        { required: true, message: 'Please select captain', trigger: 'blur' } ]}},[_c('el-select',{staticStyle:{"width":"80%"},attrs:{"multiple":""},model:{value:(_vm.bulk.captainIds),callback:function ($$v) {_vm.$set(_vm.bulk, "captainIds", $$v)},expression:"bulk.captainIds"}},_vm._l((_vm.captainOptions),function(item){return _c('el-option',{key:item.value,staticStyle:{"width":"80%"},attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"Max Member: ","prop":"memberLimit","rules":[
        {
          required: true,
          message: 'Please enter max member limit',
          trigger: 'blur',
        } ]}},[_c('el-input',{model:{value:(_vm.bulk.memberLimit),callback:function ($$v) {_vm.$set(_vm.bulk, "memberLimit", $$v)},expression:"bulk.memberLimit"}})],1),_c('el-form-item',{attrs:{"label":"Threshold: ","prop":"successMemberThreshold","rules":[
        {
          required: true,
          message: 'Please enter success member threshold',
          trigger: 'blur',
        } ]}},[_c('el-input',{model:{value:(_vm.bulk.successMemberThreshold),callback:function ($$v) {_vm.$set(_vm.bulk, "successMemberThreshold", $$v)},expression:"bulk.successMemberThreshold"}})],1),_c('el-form-item',{attrs:{"label":"Start time: ","prop":"startTime","rules":[
        {
          required: true,
          message: 'Please select start time',
          trigger: 'blur',
        } ]}},[_c('el-date-picker',{attrs:{"type":"datetime","placeholder":"Start Date","value-format":"yyyy-MM-dd HH:mm:ss"},model:{value:(_vm.bulk.startTime),callback:function ($$v) {_vm.$set(_vm.bulk, "startTime", $$v)},expression:"bulk.startTime"}})],1),_c('el-form-item',{attrs:{"label":"End time: ","prop":"endTime","rules":[
        {
          required: true,
          message: 'Please select end time',
          trigger: 'blur',
        } ]}},[_c('el-date-picker',{attrs:{"type":"datetime","placeholder":"End Date","value-format":"yyyy-MM-dd HH:mm:ss"},model:{value:(_vm.bulk.endTime),callback:function ($$v) {_vm.$set(_vm.bulk, "endTime", $$v)},expression:"bulk.endTime"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","align":"center"},on:{"click":function($event){return _vm.onSubmit('createBulkForm')}}},[_vm._v("Submit")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <el-card class="form-container" shadow="never">
    <el-form
      :model="bulk"
      ref="createBulkForm"
      label-width="150px"
      style="width: 600px"
      size="small"
    >
      <el-form-item
        label="Product: "
        prop="productId"
        :rules="[
          { required: true, message: 'Please select product', trigger: 'blur' },
        ]"
      >
        <el-input v-model="productDisplay" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item
        label="Captain: "
        prop="captainIds"
        :rules="[
          { required: true, message: 'Please select captain', trigger: 'blur' },
        ]"
      >
        <el-select v-model="bulk.captainIds" multiple style="width: 80%">
          <el-option
            v-for="item in captainOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            style="width: 80%"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="Max Member: "
        prop="memberLimit"
        :rules="[
          {
            required: true,
            message: 'Please enter max member limit',
            trigger: 'blur',
          },
        ]"
      >
        <el-input v-model="bulk.memberLimit"></el-input>
      </el-form-item>
      <el-form-item
        label="Threshold: "
        prop="successMemberThreshold"
        :rules="[
          {
            required: true,
            message: 'Please enter success member threshold',
            trigger: 'blur',
          },
        ]"
      >
        <el-input v-model="bulk.successMemberThreshold"></el-input>
      </el-form-item>
      <el-form-item
        label="Start time: "
        prop="startTime"
        :rules="[
          {
            required: true,
            message: 'Please select start time',
            trigger: 'blur',
          },
        ]"
      >
        <el-date-picker
          v-model="bulk.startTime"
          type="datetime"
          placeholder="Start Date"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
      </el-form-item>
      <el-form-item
        label="End time: "
        prop="endTime"
        :rules="[
          {
            required: true,
            message: 'Please select end time',
            trigger: 'blur',
          },
        ]"
      >
        <el-date-picker
          v-model="bulk.endTime"
          type="datetime"
          placeholder="End Date"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="onSubmit('createBulkForm')"
          align="center"
          >Submit</el-button
        >
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import { fetchActive as fetchActiveCaptainList } from '@/api/captain';
import { createBulk } from '@/api/bulk';

const defaultBulk = {
  productId: null,
  captainIds: [],
  memberLimit: '99',
  successMemberThreshold: '2',
  startTime: null,
  endTime: null,
  timeZone: 'America/Toronto',
};

export default {
  name: 'createBulk',
  data() {
    return {
      bulk: { ...defaultBulk },
      captainOptions: [],
      productDisplay: null,
    };
  },
  created() {
    this.productDisplay = this.$route.query.productName;
    this.bulk.productId = this.$route.query.productId;
    this.bulk.startTime = this.$route.query.startTime;
    this.bulk.endTime = this.$route.query.endTime;
    this.getCaptainList();
  },
  methods: {
    getCaptainList() {
      fetchActiveCaptainList().then((response) => {
        const list = response.data;
        list.forEach((captain) => {
          this.captainOptions.push({ label: captain.name, value: captain.id });
        });
      });
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm(
            'Create Bulk? Bulk would immediately published to users!',
            'Warning',
            {
              confirmButtonText: 'Yes',
              cancelButtonText: 'Cancel',
              type: 'Warning',
            },
          ).then(() => {
            createBulk(this.bulk).then((response) => {
              this.$message({
                message: 'Success',
                type: 'success',
                duration: 1000,
              });
              this.$router.back();
            });
          });
        } else {
          this.$message({
            message: 'Form Invalid',
            type: 'error',
            duration: 1000,
          });
          return false;
        }
      });
    },
  },
};
</script>
